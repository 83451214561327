<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <AuditLogsTable />
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import AuditLogsTable from "@/components/auditLogs/AuditLogsTable"

export default {
  components: {
    AuditLogsTable,
  },
}
</script>
